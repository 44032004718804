import Test from './test';
import Main from './main';
import Login from './login'
import NewsFlash from './news_flash'
import DistStore from './distribute'

export default {
    testStore: Test,
    mainStore: Main,
    loginStore: Login,
    newsFlashStore: NewsFlash,
    distStore: DistStore
}
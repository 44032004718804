import React from 'react'
import {observer, inject} from 'mobx-react';
import '../news_flash.scss'


@inject("store")
@observer
class Index extends React.Component{

    componentDidMount(){
        const {newsFlashStore} = this.props.store
        newsFlashStore.getMediaAll()
    }

    
    render() {
        return(
            <div className="container-fluid h-100 a-fadein">
                <div className="container mt-2 mb-2" >
                    
                    <div className="w-100 d-flex flex-row align-items-center flex-nowarp">
                        <div className="flex-fill p-4 d-flex flex-column align-items-center justify-content-center" style={{background: 'rgb(241,241,241)', height: 117}}>
                            <span className="mb-1" style={{color: '#999999'}}>订阅总量</span>
                            <strong>0</strong>
                        </div>
                        <div className="flex-fill p-4 d-flex flex-column align-items-center justify-content-center" style={{background: 'rgb(241,241,241)', height: 117}}>
                            <span className="mb-1" style={{color: '#999999'}}>总阅读数</span>
                            <strong>0</strong>
                        </div>
                        <div className="flex-fill p-4 d-flex flex-column align-items-center justify-content-center" style={{background: 'rgb(241,241,241)', height: 117}}>
                            <span className="mb-1" style={{color: '#999999'}}>昨日阅读</span>
                            <strong>0</strong>
                        </div>
                        <div className="flex-fill p-4 d-flex flex-column align-items-center justify-content-center" style={{background: 'rgb(241,241,241)', height: 117}}>
                            <span className="mb-1" style={{color: '#999999'}}>总数据指数</span>
                            <strong>0</strong>
                        </div>
                        <div className="flex-fill p-4 mt-4 ml-4 mb-4  d-flex flex-column align-items-center justify-content-center nf-bg">
                            <i className="ydc-icon-shu"></i>
                            <span style={{color: '#ffffff'}}>发布</span>
                        </div>
                    </div>
                    {/* ad */}
                    <div className="mb-4">
                       <img src='./assets/images/ad2.jpg' style={{width: '100%'}}></img> 
                    </div>
                    {/* 公告 */}
                    <div class="list-group">
                        <div class="list-group-item flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">公告</h5>
                            </div>  
                        </div>
                        <div href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <span class="mb-1">一点车点讯号平台关于封禁违规账号的通知（4月13日-4月20日） new</span>
                                <small>2017-04-17 10:00:00</small>
                            </div>
                        </div>
                        <div href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <span class="mb-1">一点车点讯号平台关于封禁违规账号的通知（4月13日-4月20日） new</span>
                                <small>2017-04-17 10:00:00</small>
                            </div>
                        </div>
                        <div href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <span class="mb-1">一点车点讯号平台关于封禁违规账号的通知（4月13日-4月20日） new</span>
                                <small>2017-04-17 10:00:00</small>
                            </div>
                        </div>

                        <div href="#" class="list-group-item list-group-item-action flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <span class="mb-1">一点车点讯号平台关于封禁违规账号的通知（4月13日-4月20日） new</span>
                                <small>2017-04-17 10:00:00</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Index
import React from 'react';
import { HashRouter as Router, Route, Redirect} from "react-router-dom"; //Switch ,Prompt, Link, Redirect
import { Provider} from 'mobx-react';
import Store from './store';
// import Test from './pages/Test';
import Main from './pages/Main';
import Login from './pages/Login';
import BindPage from './pages/Bind'

import {getUserInfo, set} from './static/LocalStorage'

import './App.scss';
import './animation.css'

class App extends React.Component{
  render() {
    return (
      <Provider store={Store}>
          <Router>
            <div>
            <Route path='/weibo' exact render={props=>{
                const query = props.location.search
                console.log(query)
                if(query && query.length > 0) {
                  set("weibo", query)
                }
                return <BindPage {...props}></BindPage>
              }}></Route>
              <Route path='/' exact render={props=>{
                if(getUserInfo() == null) {
                  return <Redirect to={{pathname: '/login.html'}}></Redirect>
                } else {
                  return <Main {...props}></Main>
                }
              }}></Route>
              <Route path='/login.html' exact render={props=>{
                if(getUserInfo() == null) {
                  return <Login {...props}></Login>
                } else {
                  return <Redirect to={{pathname: '/'}}></Redirect>
                }
              }}></Route>  
            </div>
          </Router>
      </Provider>
    )
  }
}

export default App;



export const nav_menus = [
    {
        id: 0,
        pid: -1,
        name: "俯瞰快讯",
        path: '1',
        children: [
            {
                id: 1,
                pid: 0,
                name: "快讯内容",
                icon: '',
                path: '1-3',
            },
            {
                id: 2,
                pid: 0,
                name: "快讯发布",
                icon: '',
                path: '1-2',
            }
        ]
    },
    {
        id: 1,
        pid: -1,
        name: "分发管理",
        path: '2',
        children: [
            {
                id: 1,
                pid: 0,
                name: "内容管理",
                icon: '',
                path: '2-1',
            },
            {
                id: 2,
                pid: 0,
                name: "授权管理",
                icon: '',
                path: '2-2',
            }
        ]
    },   
]
import React from 'react';
import {observer, inject} from 'mobx-react';


@inject("store")
@observer
class SelectTag extends React.Component{
    render() {
        const {newsFlashStore} = this.props.store
        const {
            newsFlashStore:{
                media_all,
                send_media_ids,
                media_message_list
            }} = this.props.store
        let media_ids = ''
        media_all.map(item=>media_ids += `,${item.id}`)
        media_ids = media_ids.substring(1, media_ids.length)
        return(
            <select defaultValue={send_media_ids} className="form-control mr-2 w-100" onChange={e=>newsFlashStore.setMediaIds(e.target.value)}>
                <option value={media_ids}>全部媒体</option>
                {
                    media_all.map((item, index) => {
                        return <option key={`m-${index}`} value={item.id}>{item.name}</option>
                    })
                }
            </select>
        )
    }
}
export default SelectTag
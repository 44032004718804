import {observable, action} from 'mobx';
import Api from '../utils/Api'
import {saveUserInfo} from '../static/LocalStorage';
class Login{
    @observable loading = false

    @observable error = ""

    @observable name = "chatroom_test"
    @action setLoginName(name) {
        this.name = name
    }
    @observable pwd = ""
    @action setLoginPwd(pwd) {
        this.pwd = pwd
    }

    @action login() {
        this.loading = true
        this.error = ""
        Api.login(this.name, this.pwd).then(data=>{
            console.log(data)
            if(data && 200 == data.code) {
                saveUserInfo(data.data)
                window.location.href = '/'
            }else {
                this.error = data.message
            }
            this.loading = false
        }).catch(e=>{console.log(e)})
    }


}
const login = new Login();
export default login
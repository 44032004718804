export function setSession(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value))
}

export function getSession(key) {
    return JSON.parse(sessionStorage.getItem(key));
}

export function set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}
export function get(key) {
    return JSON.parse(localStorage.getItem(key));
}
export function remove(key) {
    localStorage.removeItem(key)
}

export function callBack(res) {
    if (res && res.code == 603) {
        localStorage.removeItem('user_info')
        window.location.href = '/'
    } else {
        return res
    }
}

export function clearUserInfo() {
    sessionStorage.removeItem('user_info')
}

export function saveUserInfo(userInfo) {
    sessionStorage.setItem('user_info', JSON.stringify(userInfo));
}

export function getUserInfo() {
    return JSON.parse(sessionStorage.getItem('user_info'));
}

export function getToken() {
    return JSON.parse(sessionStorage.getItem('user_info')).token;
}

export function print(message) {
    console.log(message)
}

export function checkEmail(email) {
    var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    if (reg.test(email)) {
        return true
    } else {
        return false
    }
}

export function isPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone",
        "SymbianOS", "Windows Phone",
        "iPad", "iPod"
    ];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break;
        }
    }
    return flag;
}

export function dateFormat(date, format = 'yyyy-MM-dd HH:mm:ss') {
    if (date && date instanceof Date) {


        let dm = ""
        if ((date.getMonth() + 1) <= 9) {
            dm = `0${(date.getMonth() + 1)}`
        } else {
            dm = `${(date.getMonth() + 1)}`
        }


        let dd = ""
        if ((date.getDate()) <= 9) {
            dd = `0${(date.getDate())}`
        } else {
            dd = `${(date.getDate())}`
        }


        let dateStr = `${date.getFullYear()}/${dm}/${dd}`

        if (format == "yyyy-MM-dd HH:mm:ss") {
            dateStr = `${dateStr} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
        }
        return dateStr
    } else {
        return undefined
    }
}
/**
 *  日期加减
 */
export function date2Day(date, day, format = 'yyyy-MM-dd HH:mm:ss') {
    let news = null
    if (format == "yyyy-MM-dd HH:mm:ss") {
        news = new Date(`${date.getFullYear()}/${(date.getMonth()+1)}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`)
    } else {
        news = new Date(`${date.getFullYear()}/${(date.getMonth()+1)}/${date.getDate()}`)
    }
    news.setDate(news.getDate() + day)
    return news
}
/**
 * 
 * @param {*} val 
 */
export function checkChinese(val) {
    // 是否包含中文
    let reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    if (reg.test(val)) {
        return true
    } else {
        return false
    }
}


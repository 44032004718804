import request from './Request';
import {getToken, dateFormat} from '../static/LocalStorage'
import md5 from './md5'

// const HOST = "http://47.104.211.22"
const HOST = "https://www.vulcandata.cn"

/**
 * 测试专用
 * @param {*} url 
 */
function text(url){
    return request.get(url)
}
/**
 * 登录
 * @param {*} login_name 
 * @param {*} login_pwd 
 */
function login(login_name, login_pwd) {
    return request.post(`https://www.vulcandata.cn/sso/user/sys/login?tag=mwSSeROhnWxypVPp1zmaNJJS2JTYVp6f`, {loginName: login_name, password: md5(login_pwd)})
}
/**
 *  获取自己的所有的媒体
 */
function mediaList() {   
    return request.getApi(`${HOST}/chatroom/chat/check/channels/list`, getToken())
}
/**
 *  
 */
function messageStatusList() {
    return request.getApi(`${HOST}/chatroom/system/get?tableName=chat/check/message&field=status`, getToken())
}

function getMessage(page, count=50) {
    return request.postApi(`${HOST}/chatroom/chat/check/message/page/${page}/${count}`, {}, getToken())
}

function sendMessage(params) {
    return request.postApi(`${HOST}/chatroom/chat/check/message/add`, params, getToken())
}


/////
const list = [
    {
        id: "",
        content: "“站位高、落点实”“担当有为，卓有成效”“彰显法治特色，体现出深厚的为民情怀和强烈的责任担当”“一本新时代法治中国‘故事书’、一幅人民代表大会制度在新时代完善发展的‘全景图’”……5月25日，全国人大常委会工作报告提交十三届全国人大三次会议审议。与会代表纷纷为在不平凡的一年多时间里全国人大常委会的各项工作点赞。",
        url: "https://news.163.com/20/0526/12/FDI74VVJ000189FH.html",
        image_url: "https://cms-bucket.ws.126.net/2020/0526/414938a0j00qaxis5009ic0045602rgc.jpg",
        video_url: "https://flv.bn.netease.com/a6d0ae48f189c7985750d8c4481b75a35a8a485adc4c45b12086962fb6c6b702c3d575980e147bb46785403f5a612dacea2c914b3642cae79e9c298338f20d0c3cb5a18ec47b0a5fc5766c3589bcccbfe0c7fa9fa63a0e9de0e506b7f999a6a89abd18c1f153151c565cd1f9bdd659bf5186a1c97f448415.mp4"
    },
    {
        id: "",
        content: "“站位高、落点实”“担当有为，卓有成效”“彰显法治特色，体现出深厚的为民情怀和强烈的责任担当”“一本新时代法治中国‘故事书’、一幅人民代表大会制度在新时代完善发展的‘全景图’”……5月25日，全国人大常委会工作报告提交十三届全国人大三次会议审议。与会代表纷纷为在不平凡的一年多时间里全国人大常委会的各项工作点赞。",
        url: "https://news.163.com/20/0526/12/FDI74VVJ000189FH.html",
        image_url: "",
        video_url: "https://flv.bn.netease.com/a6d0ae48f189c7985750d8c4481b75a35a8a485adc4c45b12086962fb6c6b702c3d575980e147bb46785403f5a612dacea2c914b3642cae79e9c298338f20d0c3cb5a18ec47b0a5fc5766c3589bcccbfe0c7fa9fa63a0e9de0e506b7f999a6a89abd18c1f153151c565cd1f9bdd659bf5186a1c97f448415.mp4"
    },
    {
        id: "",
        content: "“站位高、落点实”“担当有为，卓有成效”“彰显法治特色，体现出深厚的为民情怀和强烈的责任担当”“一本新时代法治中国‘故事书’、一幅人民代表大会制度在新时代完善发展的‘全景图’”……5月25日，全国人大常委会工作报告提交十三届全国人大三次会议审议。与会代表纷纷为在不平凡的一年多时间里全国人大常委会的各项工作点赞。",
        url: "https://news.163.com/20/0526/12/FDI74VVJ000189FH.html",
        image_url: "https://cms-bucket.ws.126.net/2020/0526/414938a0j00qaxis5009ic0045602rgc.jpg",
        video_url: ""
    }
]

const account_list = [{
    id: Math.random(),
    avatars: "", 
    name: "test001"
}]


function getContentDataList(page = 1, total = 15){
    return request.getApi(`${HOST}/chatroom/share/message/page/${page}/${total}`, getToken())
}


function saveArticle(obj) {
    return request.postApi(`${HOST}/chatroom/share/message/add`, obj, getToken())
}


function getPlatformList(){
    return request.getApi(`${HOST}/chatroom/share/list`, getToken())
    
}




function bindAccount() {
    return request.getApi(`${HOST}/chatroom/user/share/bindList`, getToken())
}

function txt2abstract(content){
    return request.postApi(`${HOST}/chatroom/digest/get`, {content: content}, getToken())
}

function txt2image(content){
    let params = {
        "list": []
    } 
    params.list.push({
        type: "text",
        text: content,
        id: "",
        source: "俯瞰AI",
        createDate: dateFormat(new Date()).replace(/\//g, '-'),
        title: ""
    })
    return request.postApi(`${HOST}/mer/graphics/combination1`, params, getToken())
}

function txt2Audio(content){
    return request.postApi(`${HOST}/tea/test/speech`, {content: content}, getToken())
}

function txt2vedio(title, content){
    return request.postApi(`${HOST}/mer/videoTask/addVulcan`, {
        "txtDataSet":[
            {
                title: title,
                txt: content,
                sort: 1
            }
        ]
    }, getToken())
}

function getTxt2VedioStatus(id){
    return request.getApi(`${HOST}/mer/videoTask/status?id=${id}`)
}

function shareArticle(article){
    return request.getApi(`${HOST}/chatroom/share/message/share?id=${article.id}`)
}

export default {
    text,
    login,
    mediaList,
    messageStatusList,
    getMessage,
    sendMessage,

    getTxt2VedioStatus,
    txt2Audio,
    txt2vedio,
    txt2image,
    txt2abstract,
    getContentDataList,
    saveArticle,
    getPlatformList,
    bindAccount,
    shareArticle
}
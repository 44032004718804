import React, { Fragment } from 'react';
import {observer, inject} from 'mobx-react';
import {getToken} from '../../../static/LocalStorage';
// import {Link} from "react-router-dom";
@inject("store")
@observer
class Authorization extends React.Component{

    componentDidMount(){
        const {distStore} = this.props.store 
        distStore.getPlatformList(()=>{
            distStore.getSelectPlatformAccountList()
        })
    }

    render() {
        const {distStore: {
            select_platform,
            platform_list,
            platform_account_list
        }} = this.props.store
        const {distStore} = this.props.store 
        return(
            <div className="container-fluid h-100 a-fadein">
                <div className="container p-2" style={{height: '98%', backgroundColor: "#ffffff"}}>

                    <div className="w-100 d-flex flex-row align-items-center">
                        {
                            platform_list.map((platform, index) => {
                                return (
                                    <div key={`platform-${index}`} onClick={()=>distStore.setSelectPlatform(platform)} className={(select_platform != null && select_platform.platform_id == platform.platform_id) ? "cursor border border-top-0 border-left-0 border-right-0 p-2 px-4 border-primary" : "cursor border border-top-0 border-left-0 border-right-0 p-2 px-4"}>{platform.name}</div>
                                )
                            })
                        } 
                    </div>
                    {
                        platform_list.length > 0 ? 
                        <Fragment>
                            <div className="w-100 mt-2 p-2 d-flex flex-row align-items-center justify-content-between border border-top-0 border-left-0 border-right-0">
                                <button onClick={()=>{
                                    distStore.getTask()
                                    let open_url = select_platform.url.replace('{token}', getToken())
                                    window.open(open_url)
                                }} className="btn btn-primary">绑定账号</button>
                            </div>
                            <div className="w-100 d-flex flex-row align-items-center p-3">
                                {
                                    platform_account_list.map((account, index) => {
                                        return (
                                            
                                                <div key={index} className="d-flex flex-row align-items-center justify-content-center p-2 border m-2" style={{width: 200}}>
                                                    <div className="thumbnail">
                                                        <img style={{width: 125}} src={account.avater} alt="..." />
                                                        <div className="caption text-center">
                                                            <strong>{account.name}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                        )
                                    })
                                }
                            </div>
                            
                        </Fragment>:""
                    }
                    

                </div> 
            </div>
        )
    }
}

export default Authorization
import React, { Fragment } from 'react'
import {observer, inject} from 'mobx-react';
import {nav_menus} from '../../static/nav_config';

@inject("store")
@observer
class Navigation extends React.Component{

    state = {
        selectItem: []
    }

    selectItemChildrenShow(item){
        let flag = false
        this.state.selectItem.map(it => {
            if(it.name == item.name) {
                flag = true
            }
        })
        return flag
    }

    addSelectItem(item) {
        this.state.selectItem.push(item)
        this.setState({
            selectItem: [... this.state.selectItem]
        })
    }

    removeSelectItem(item){
        let array = []
        this.state.selectItem.map(it => {
            if(it.name == item.name) {
                
            }else {
                array.push(it)
            }
        })
        this.setState({
            selectItem: [... array]
        })
    }

    onItemClick(menu_item) {
        const {mainStore, newsFlashStore} = this.props.store
        mainStore.setUiStatus(menu_item.path)
        newsFlashStore.resetData()
        if(this.selectItemChildrenShow(menu_item)) {
            this.removeSelectItem(menu_item)
        } else {
            this.addSelectItem(menu_item)
        }
    }

    render() {
        return(
            <Fragment>
                {
                    nav_menus.map((menu_item, m_index) => {
                        let flag = this.selectItemChildrenShow(menu_item)
                        let children = menu_item.children
                        return (
                            <Fragment key={`f-${m_index}`}>
                                <NavigationItem key={`r-${m_index}`} item={menu_item} onItemClick={()=>this.onItemClick(menu_item)}></NavigationItem>
                                {
                                    flag ? children.map((childrenItem, i )=> {
                                        return <NavigationItem sub key={`c-${i}`} item={childrenItem} onItemClick={()=>this.onItemClick(childrenItem)}></NavigationItem>
                                    }) : ""
                                }
                            </Fragment>
                        )
                    })
                }
            </Fragment>
        )
    }
}

class NavigationItem extends React.Component{

    render() {
        const {item, onItemClick, sub} = this.props
        return (<div onClick={()=>onItemClick ? onItemClick(item): ""} className="cursor w-100 d-flex flex-row align-items-center pl-4 pr-4 a-fadein" style={{height: 40}}>
                    {
                        sub ? 
                        <div style={{width: 32, height: 32}}></div> : ""
                    }
                    <div className="mr-2">
                        {
                            sub ? 
                            <svg t="1589511588232" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6474" width="28" height="28"><path d="M460.650667 192a64 64 0 0 1 62.485333 50.133333l3.093333 13.866667H789.333333a64 64 0 0 1 64 64v448a64 64 0 0 1-64 64H234.666667a64 64 0 0 1-64-64V256a64 64 0 0 1 64-64h225.984zM746.666667 640H277.333333v64h469.333334v-64z m42.666666-320H540.437333l9.493334 42.666667H789.333333v-42.666667z" p-id="6475" fill="#ffffff"></path></svg>
                            :
                            <svg t="1589513183765" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6900" width="28" height="28"><path d="M810.666667 298.666667a64 64 0 0 1 64 64v426.666666a64 64 0 0 1-64 64l-81.130667 0.021334a127.274667 127.274667 0 0 0 16.981333-57.706667L746.666667 789.333333V298.666667h64z m-170.666667-128a64 64 0 0 1 64 64v554.666666a64 64 0 0 1-64 64H213.333333a64 64 0 0 1-64-64V234.666667a64 64 0 0 1 64-64h426.666667z m-192 320h-170.666667v64h170.666667v-64z m128-128H277.333333v64h298.666667v-64z" p-id="6901" fill="#ffffff"></path></svg>
                        }
                    </div>
                    {item.name}
                </div>)
    }
}

export default Navigation
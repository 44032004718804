import React from 'react'
import '../news_flash.scss'
import {observer, inject} from 'mobx-react';
import Page from '../../../component/Page'

@inject("store")
@observer
class Index extends React.Component{

    componentDidMount(){
        const {newsFlashStore} = this.props.store
        newsFlashStore.getMediaAll()
        newsFlashStore.search()
    }

    toEdit(item) {
        const {newsFlashStore, mainStore} = this.props.store

        newsFlashStore.setMessageId(item.id)
        newsFlashStore.setTitle(item.title)
        newsFlashStore.setContent(item.message)
        newsFlashStore.setMediaIds(item.media_ids)
        newsFlashStore.setStatus(item.status)
        newsFlashStore.setUrl(item.url)

        mainStore.setUiStatus('1-2')
    }

    render() {
        const {newsFlashStore, mainStore} = this.props.store
        const {
            newsFlashStore:{
                media_all,
                media_message_list,
                totle,
                page,
                news_status,
                count
            }} = this.props.store
        let media_ids = ''
        media_all.map(item=>media_ids += `,${item.id}`)
        media_ids = media_ids.substring(1, media_ids.length)
        
        // 计算页数
        let page_num = 0
        if(totle > 0 && totle % count > 0) {
            page_num = 1
        }
        if(totle > count) {
            page_num +=(Math.floor(totle/count))
        }
        
        
        // console.log(page, page_num)

        return(
            <div className="container-fluid h-100 a-fadein">
                <div className="container py-2 mb-2" style={{backgroundColor: '#fff'}}>
                    {/* 搜索条 */}
                    {/* <div className="mt-4 p-2 w-100 d-flex flex-row align-items-center" >
                        
                        <select className="form-control mr-2">
                            <option value={media_ids}>全部媒体</option>
                            {
                                media_all.map((item, index) => {
                                    return <option key={`m-${index}`} value={item.id}>{item.name}</option>
                                })
                            }
                        </select>

                        <select className="form-control mr-2">
                            <option value="1">全部状态</option>
                            {
                                news_status.map((item, index) => {
                                    return <option key={`status-${index}`} value={item.key}>{item.value}</option>
                                })
                            }
                        </select>

                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="button">搜索</button>
                            </div>
                        </div>

                    </div> */}
                    {/* 内容 */}
                    <table className="table table-bordered row mx-0 table-hover table-striped mt-3 mb-3">
                        <thead className="w-100">
                            <tr className="row w-100 mx-0">
                                <th className="col-2 border-0">标题</th>
                                <th className="col-5 border-top-0 border-right-0 border-bottom-0">内容</th>
                                <th className="col-2 border-top-0 border-right-0 border-bottom-0">时间</th>
                                <th className="col-1 border-top-0 border-right-0 border-bottom-0">媒体</th>
                                <th className="col-1 border-top-0 border-right-0 border-bottom-0">状态</th>
                                <th className="col-1 border-top-0 border-right-0 border-bottom-0">操作</th>
                            </tr>
                        </thead>
                        <tbody className="w-100">
                            {
                                media_message_list.map((message, index) => {
                                    let status = ""
                                    news_status.map(s=>{
                                        if(s.key == message.status) {
                                            status = s.value
                                        }
                                    })
                                    let media_names = ""
                                    message.media_ids.split(',').map(mm => {
                                        media_all.map(media=>{
                                            if(media.id == mm) {
                                                media_names += `,${media.name}`
                                            }
                                        })
                                    })
                                    media_names = media_names.substring(1, media_names.length)
                                    
                                    return (
                                        <tr key={`message-${index}`} className="row w-100 mx-0">
                                            <td className="col-2 d-inline-block text-truncate border-right-0 border-left-0 border-bottom-0">{(message.title && message.title.length > 0) ? message.title : "没有标题"}</td>
                                            <td className="col-5 d-inline-block text-truncate border-bottom-0 border-right-0">{message.message}</td>
                                            <td className="col-2 border-bottom-0 border-right-0">{message.createDate}</td>
                                            <td className="col-1 d-inline-block text-truncate border-bottom-0 border-right-0">{media_names}</td>
                                            <td className="col-1 border-bottom-0 border-right-0">{status}</td>
                                            <td className="col-1 border-left-1 border-bottom-0 border-right-0"><div onClick={()=>this.toEdit(message)} className="button" role="button">编辑</div></td>
                                        </tr>
                                    )
                                })
                            }
                                
                        </tbody>
                    </table>
                    {/*  */}
                    {media_message_list.length > 0 && page_num > 0 ? 
                    <Page pageCallbackFn={pageNum=>{newsFlashStore.setPage(pageNum)}} totalPage={page_num} currentPage={page}></Page>
                    :
                    ""}
                </div>
            </div>
        )
    }
}

export default Index
import React, { Fragment } from 'react'
import {observer, inject} from 'mobx-react';
import Navigation from './../Navigation';
import Loading from '../../component/Loading';
import {getUserInfo} from '../../static/LocalStorage';

import NewsFlashHomePage from '../NewsFlash/HomePage'
import NewsFlashSendPage from '../NewsFlash/SendPage'
import NewsFlashDatePage from '../NewsFlash/DataPage'

import DistributeAuthorPage from '../Distribute/Authorization'
import DistributeContentPage from '../Distribute/Content'

import './main.scss'

@inject("store")
@observer
class Main extends React.Component{


    render() {
        const {mainStore, newsFlashStore} = this.props.store
        const {
            mainStore: {
                ui_status
            },
            newsFlashStore: {
                loading
            }
        } = this.props.store
        return(
            <div className="main d-flex flex-row">
                {
                    (loading) ? <Loading></Loading> : ""
                }

                
                {/* main_nav */}
                <div className="d-flex flex-column  border-right main_nav">
                    <div className="d-flex flex-colmun align-items-center justify-content-center logo">
                        <span>俯瞰</span>
                    </div>
                    <div className="nav-pills flex-colnum">
                        <Navigation></Navigation>
                    </div>
                </div>

                {/* main_content */}
                <div className="main_content d-flex flex-column">
                    <div className="w-100 border-bottom user d-flex flex-row align-items-center justify-content-center">
                        <div className="container-fluid d-flex flex-row align-items-center justify-content-between">
                            
                            <i className="angle-double-left"></i>
                            
                            <div>{getUserInfo().nickName}</div>
                        </div>
                    </div>
                    <div style={{overflowY: "auto", height: '100%', backgroundColor: 'rgb(239,239,239)'}}>
                    {/* main */}
                    {/* {ui_status == '1' || ui_status == "1-1" ? <NewsFlashHomePage></NewsFlashHomePage> : ""} */}
                    {ui_status == "1-2" ? <NewsFlashSendPage></NewsFlashSendPage> : ""}
                    {ui_status == "1-3" ? <NewsFlashDatePage></NewsFlashDatePage> : ""}

                    {ui_status == "2-1" ? <DistributeContentPage></DistributeContentPage> : ""}
                    {ui_status == "2-2" ? <DistributeAuthorPage></DistributeAuthorPage> : ""}
                    </div>
                </div>
                
            </div>
        )
    }
}

export default Main
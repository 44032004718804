import React from 'react'
import {observer, inject} from 'mobx-react';
import Loading from '../../component/Loading';
import './login.scss'

@inject("store")
@observer
class Login extends React.Component{

    render(){
        const {loginStore: {
            loading,
            name,
            pwd,
            error
        }} = this.props.store
        const {loginStore} = this.props.store
        return(
            <div className="login_logo d-flex flex-row justify-content-center">
                <div className="" style={{marginTop: '15%', width: '500px'}}>
                    <h4 className="text-center"><strong>俯瞰管理</strong></h4>
                    <div className="input-group mt-4 mb-3 border p-1">
                        <div className="input-group-prepend">
                            <span className="input-group-text" style={{padding: 0, background: "transparent", border: 0}}><svg t="1591686078572" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6829" width="30" height="30"><path d="M644.8 581.568l160.64 187.456A64 64 0 0 1 756.842667 874.666667H544v-192h-64v192h-212.842667a64 64 0 0 1-48.597333-105.642667l160.661333-187.434667A254.805333 254.805333 0 0 0 512 618.666667c48.64 0 94.08-13.546667 132.8-37.098667zM512 149.333333c117.824 0 213.333333 95.509333 213.333333 213.333334s-95.509333 213.333333-213.333333 213.333333-213.333333-95.509333-213.333333-213.333333S394.176 149.333333 512 149.333333z" p-id="6830" fill="#e6e6e6"></path></svg></span>
                        </div>
                        <input value={name} onChange={e=>loginStore.setLoginName(e.target.value)} type="text" className="form-control login-form-control" style={{background: "transparent", border: 0, color: '#fff'}} placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                    <div className="input-group mt-3 mb-4 border p-1">
                        <div className="input-group-prepend">
                            <span className="input-group-text" style={{padding: 0, background: "transparent", border: 0}}><svg t="1591686160711" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7015" width="30" height="30"><path d="M512.704 106.666667a181.333333 181.333333 0 0 1 181.333333 181.333333l-0.021333 53.333333H789.333333a64 64 0 0 1 64 64v384a64 64 0 0 1-64 64H234.666667a64 64 0 0 1-64-64V405.333333a64 64 0 0 1 64-64h96.682666v-53.333333a181.333333 181.333333 0 0 1 181.333334-181.333333z m37.418667 405.333333h-64v170.666667h64v-170.666667zM512.704 170.666667a117.333333 117.333333 0 0 0-117.333333 117.333333V341.333333h234.666666v-53.333333a117.333333 117.333333 0 0 0-117.333333-117.333333z" p-id="7016" fill="#e6e6e6"></path></svg></span>
                        </div>
                        <input value={pwd} onChange={e=>loginStore.setLoginPwd(e.target.value)} type="password" className="form-control login-form-control" style={{background: "transparent", border: 0, color: '#fff'}} placeholder="Password" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                    <div className="mb-3">
                        {error}
                    </div>
                    <button onClick={()=>loginStore.login()} className="btn btn-primary btn-block">Login</button>
                </div>
            </div>
        )
    }
}

export default Login
import React, {Fragment} from 'react';
import Page from '../Page'

class Table extends React.Component{

    state = {
        data_list: this.props.dateList ? this.props.dateList : [],
        page_num: this.props.pageNum ? this.props.pageNum : 0,
        page: this.props.page ? this.props.page : 0
    }

    render() {
        return (
            <Fragment>
                <table className="table table-bordered row mx-0 table-hover table-striped mt-3 mb-3">
                        <thead className="w-100">
                            {
                                this.props.renderTableTitle()
                            }
                            {/* <tr className="row w-100 mx-0">
                                <th className="col-2 border-0">标题</th>
                                <th className="col-5 border-top-0 border-right-0 border-bottom-0">内容</th>
                                <th className="col-2 border-top-0 border-right-0 border-bottom-0">时间</th>
                                <th className="col-1 border-top-0 border-right-0 border-bottom-0">媒体</th>
                                <th className="col-1 border-top-0 border-right-0 border-bottom-0">状态</th>
                                <th className="col-1 border-top-0 border-right-0 border-bottom-0">操作</th>
                            </tr> */}
                        </thead>
                        <tbody className="w-100">
                            {
                                this.props.children ? this.props.children : ""
                            }     
                        </tbody>
                    </table>
                    
                {this.state.data_list.length > 0 && this.state.page_num > 0 ? 
                    <Page pageCallbackFn={pageNum=>{
                        if(this.props.onPageChanage) {
                            this.props.onPageChanage(pageNum)
                        }
                    }} totalPage={this.state.page_num} currentPage={this.state.page}></Page>
                    :
                ""}
            </Fragment>
        )
    }
}


export default Table
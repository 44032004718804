import React, {Component} from 'react';
import './modal.scss'

class Modal extends Component{
    render() {
        return(
            <div className="vm-modal d-flex flex-row align-items-center justify-content-center">
                {this.props.children}
            </div>
        )
    }
}

export default Modal
export const news_types = [
    {
        name: "宏观"
    },
    {
        name: "国际"
    },
    {
        name: "焦点"
    },
    {
        name: "金融",
        children: [
            {
                name: "央行"
            },
            {
                name: "银行"
            },
            {
                name: "外汇"
            },
            {
                name: "保险"
            },
            {
                name: "基金"
            },
            {
                name: "债券"
            },
            {
                name: "信托"
            },
            {
                name: "商品"
            },
            {
                name: "互金"
            }
        ]
    },
    {
        name: "行业"
    },
    {
        name: "证券",
        children: [
            {
                name: "A股"
            },
            {
                name: "美股"
            },
            {
                name: "港股"
            },
            {
                name: "科创版"
            },
            {
                name: "其他股票"
            }
        ]
    },
    {
        name: "公司",
        children: [
            {
                name: "上市公司"
            },
            {
                name: "非上市公司"
            },
            {
                name: "外国公司"
            }
        ]
    },
    {
        name: "股权投资",
        children: [
            {
                name: "创投"
            },
            {
                name: "IPO"
            }
        ]
    },
    {
        name: "观点"
    },
    {
        name: "数据"
    },
    {
        name: "其他"
    }
]


export const news_status = [
    {
        name: "发布/上线"
    },
    {
        name: "待发布/待审核"
    },
    {
        name: "下架/下线"
    }
]
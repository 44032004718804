import React from 'react'
import SelectMediaAll from '../component/SelectTag'
import {observer, inject} from 'mobx-react';
import {news_types} from '../../../static/news_type_config';

import '../news_flash.scss'
@inject("store")
@observer
class Index extends React.Component{

    state = {
        newsTypes: news_types,
        children_types: null,
        selectTag_1: null,
        selectTag_2: null
    }

    componentDidMount(){
        const {newsFlashStore} = this.props.store
        newsFlashStore.getMediaAll()
    }


    render() {
        const {newsFlashStore} = this.props.store
        const {
            newsFlashStore:{
                news_status,
                error,

                message_title,
                abstract,
                message_content,
                message_url,
                message_status,
                send_media_ids
            }} = this.props.store

        console.log(`message_content: ${message_content.length > 0}`)
        return(
            <div className="container-fluid h-100 a-fadein" >
                <div className="container pt-2 mb-2 nf-color" style={{backgroundColor: '#fff'}}>

                    {/* <ul className="nav pb-1 mb-1 w-100 border-bottom">
                        <li className="nav-item nf-color-news-flash">发布文章</li>
                    </ul> */}

                    {error && error.length > 0 ?
                         <div className="mt-4 p-2 w-100 d-flex flex-row align-items-center" style={{background: 'rgb(251,233,232)'}}>
                            {error}
                        </div>:""
                    }

                    <div className="mt-4 p-2 w-100 d-flex flex-row align-items-center" style={{background: 'rgb(251,233,232)'}}>
                        标题字数需在30字之内，也支持没有标题。快讯内容字数在500字以内。
                    </div>

                    
                    <div className="input-group mb-3 mt-3">
                        <input maxLength={30} value={message_title} onChange={e=>newsFlashStore.setTitle(e.target.value)} type="text" className="form-control" placeholder="标题"  />
                        <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">{message_title.length}/30</span>
                        </div>
                    </div>

                    {/* <div className="input-group mb-3">
                        <textarea maxLength={510} defaultValue={abstract} onChange={e=>newsFlashStore.setAbstract(e.target.value)} rows="6" className="form-control" placeholder="摘要"></textarea>
                    </div> */}

                    {
                    (message_content && message_content.length > 0) ?
                        <div className="input-group">
                            <textarea maxLength={510} value={message_content} onChange={e=>newsFlashStore.setContent(e.target.value)} rows="6" className="form-control" placeholder="内容"></textarea>
                        </div> :  
                        <div className="input-group 1">
                           <span></span>
                           <textarea  onChange={e=>newsFlashStore.setContent(e.target.value)} rows="6" className="form-control" placeholder="内容"></textarea>
                        </div>
                    }

                    <div className="input-group mb-3 mt-3">
                        <input value={message_url} onChange={e=>newsFlashStore.setUrl(e.target.value)} type="text" className="form-control" placeholder="文章URL"  />
                        {/* <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">{message_title.length}/30</span>
                        </div> */}
                    </div>

                    <div className="mt-3 d-flex flex-row align-items-center">
                        <span style={{width: 100}}>发布媒体:</span>
                        <SelectMediaAll></SelectMediaAll>
                    </div>
 
                    <div className="mt-3 d-flex flex-row align-items-center">
                        <span style={{width: 100}}>消息状态:</span>
                        <select className="form-control mr-2 w-100" defaultValue={message_status} onChange={e=>newsFlashStore.setStatus(e.target.value)}>
                            {
                                news_status.map((item, index) => {
                                    return <option key={`status-${index}`} value={item.key}>{item.value}</option>
                                })
                            }
                        </select>
                    </div>

                    {/* {
                       <div className="mt-3 d-flex flex-row align-items-center">
                           <span style={{width: 100}}>消息分类:</span>
                           <select className="form-control mr-2 w-100" onChange={e=>{
                               let tag_name = e.target.value
                               this.state.newsTypes.map(it=>{
                                   if( tag_name == it.name ) {
                                       this.setState({
                                            children_types: it.children ? it.children : null
                                       })
                                       newsFlashStore.setMessageType1(tag_name)
                                       newsFlashStore.setMessageType2(it.children ? it.children[0].name : null)
                                   }
                               })
                           }}>
                               {
                                   this.state.newsTypes.map((types, index) => {
                                        return <option key={`opt-${index}`} value={types.name}>{types.name}</option>
                                   })
                               }
                           </select>
                       </div> 
                    }

                    {
                        (this.state.children_types) ? <div className="mt-3 d-flex flex-row align-items-center">
                           <span style={{width: 100}} onChange={e=>newsFlashStore.setMessageType2(e.target.value)}>消息分类:</span>
                           <select className="form-control mr-2 w-100">
                               {
                                   this.state.children_types.map((types, index) => {
                                        return <option key={`opt-${index}`} value={types.name}>{types.name}</option>
                                   })
                               }
                           </select>
                       </div> : ""
                    }
                    */}

                    <div className="mt-4 p-2 w-100 d-flex flex-row align-items-center">
                        <button onClick={()=>newsFlashStore.sendMessage()} type="button" className="btn btn-danger mr-4">发布</button>

                        {/* <button type="button" className="btn btn-light">保存草稿箱</button> */}
                    </div>
                   

                </div>
            </div>
        )
    }
}

export default Index
import {observable, action} from 'mobx';
import Api from '../utils/Api'
import {get, remove} from '../static/LocalStorage';
import {toastIt} from '../component/toast';


class DistributeStore{
    shareType = null
    @observable shareModalShow = false
    @action setShareModealShow(flag, type = null) {
        this.shareModalShow = flag
        this.shareType = type
        if(flag) {
            if(this.platform_account_list_all > 0) {
                this.filterAccount(this.platform_account_list_all)
            }else {
                this.getPlatformList(()=>{
                    this.getSelectPlatformAccountList((list_all)=>{
                        this.filterAccount(list_all)
                    })
                })
            }
            
        }
    }
    @observable share_platform_account = []
    @action filterAccount(list_all){
        console.log(this.shareType, list_all)
        let array = []
        list_all.map(account => {
            if(account.supportType.indexOf(this.shareType) != -1) {
                array.push({...account, select: false})
            }
        })
        this.share_platform_account = array
    }

    @action selectShareAccount(acc) {
        let array = []
        this.share_platform_account.map(account=>{
            if(account.id == acc.id) {
                account.select = !account.select
                array.push({...account}) 
            }
        })
        this.share_platform_account = array
    }

    @action sendShare(){
        let flag = false
        let ids = ""
        this.share_platform_account.map(account=>{
            if(account.select) {
                flag = true
                ids = "," + account.id
            }
        })

        if(flag) {
            this.content_edit_item.shareId = ids.substring(1)
            console.log(this.content_edit_item)
            Api.saveArticle(this.content_edit_item).then(res=>{
                if(res && 200 == res.code){
                    Api.shareArticle(res.data).then(rres=>{
                        console.log(rres)
                        this.shareModalShow = false
                        this.share_platform_account = []
                        setTimeout(()=>{
                            toastIt("分享任务成功提交", 2500, {fontSize: '16px'})
                        }, 500)
                        
                    }).catch(e=>{})
                }
            }).catch(e=>{})
        }else {
            toastIt("请选择分享的账号", 2500, {fontSize: '16px'})
        }
    }

    @observable content_data_list = []
    @action getContentDataList() {
        Api.getContentDataList(this.page, this.count).then(res=>{
            if(res && 200 == res.code) {
                this.total = res.data.total
                this.content_data_list = res.data.list
            }
        }).catch(e=>{})
    }

    @action saveArticle() {
        console.log(`saveArticle`)
        let data = {
            title: "",
            content: this.article_abstract.length > 0 ? this.article_abstract : this.article_content,
            url: this.article_url,
            image_url: "",
            vedio_url: ""
        }

        Api.saveArticle(data).then(res => {
            if(res && 200 == res.code) {
                console.log(res.data)
                let temp = []
                this.content_data_list.map(item=>{
                    temp.push({...item})
                })
                temp.push({...res.data})
                this.content_data_list = temp
            }
            this.content_edit_item = null
        }).catch(e=>{
            this.content_edit_item = null
        })
    }


    @observable content_edit_item = null
    @action setEdit(item) {
        this.content_edit_item = item
    }
    
    @observable article_content = "" //输入的文章内容
    @observable article_url = "" //输入的文章URL
    @observable article_abstract = "" //输入的摘要
    @action setArticleContent(txt) {
        this.article_content = txt
        if (this.article_content.length > 500) {
            this.txt2abstract(this.article_content)
        }
    }
    @action setArticleUrl(url) {
        this.article_url = url
    }
    @action setArticleAbstract(abstract) {
        this.article_abstract = abstract
    }
    //////////////////////
    @action txt2abstract(content){
        Api.txt2abstract(content).then(res=>{
            if(res && 200 == res.code) {
                this.article_abstract = res.data
            }
        }).catch(e=>{})
    }

    @observable platform_list = [] // 平台集合
    @action getPlatformList(call) {
        Api.getPlatformList().then(res => {
            if(res && 200 == res.code) {
                console.log(res)
                this.select_platform = res.data[0]
                this.platform_list = res.data 
                if (call) {
                    call()
                }
            }
        })
    }
    
    // 当前选择那个平台
    @observable select_platform = null
    @action setSelectPlatform(platform) {
        this.select_platform = platform
        this.data_to_data()
    }
    
    // 获取绑定码
    timer = null
    @action getTask(){
        if(this.timer != null) {
            clearInterval(this.timer)
            this.timer = null
        }
        this.timer = setInterval(()=>{
            let weibo = get('weibo')
            console.log(`task start : ${weibo}`)
            if(weibo != null) {
                remove('weibo')
                this.getSelectPlatformAccountList()
                this.data_to_data()
                clearInterval(this.timer)
                this.timer = null
                
            }
        }, 1000)
    }

    // 当前选择平台绑定用户集合
    platform_account_list_all = []
    @observable platform_account_list = []
    @action getSelectPlatformAccountList(call){
        Api.bindAccount().then(res=>{
            console.log(res)
            if(res && 200 == res.code) {
                let array = []
                res.data.map(account=>{
                    this.platform_list.map(platform=>{
                        if(platform.platform_id == account.platform_account_id) {
                            array.push({
                                ...platform,
                                ...account
                            })
                        }
                    })
                })
                this.platform_account_list_all = array
                if(call) {
                    call(this.platform_account_list_all)
                }
                // this.platform_account_list = Array.from(res.data).filter(it=>it.platform_account_id == this.select_platform.platform_id)
            }
        })
    }

    @action data_to_data(){
        this.platform_account_list = this.platform_account_list_all.filter(it=>it.platform_account_id == this.select_platform.platform_id)
    }

    @action createImage(dataItem){
        Api.txt2image(dataItem.content).then(res=> {
            console.log(res)
            if(res && 200 == res.code) {
               this.content_edit_item.media = res.data
               Api.saveArticle(this.content_edit_item).then(rres=>{
                console.log(rres)
               }).catch(e=>{})
            }
        }).catch(e=>{})
    }

    @action createVedio(dataItem) {
        Api.txt2vedio("", dataItem.content).then(res=>{
            console.log(res)
            if(res && 200 == res.code) {
                this.content_edit_item.video = res.data
                Api.saveArticle(this.content_edit_item).then(rres=>{
                    console.log(rres)
                   }).catch(e=>{})
             }
        }).catch(e=>{})
    }

    @action getCreateVedioStatus(id) {
        Api.getTxt2VedioStatus(id).then(res=>{
            console.log(res)
        }).catch(e=>{})
    }

    @action selectVedioStatus(id) {
        Api.getTxt2VedioStatus(id).then(res=>{
            console.log(res)
            if(res && 200 == res.code) {
                if(res.data.url.length > 0) {
                    console.log(res.data.url)
                    this.content_edit_item.video = res.data.url
                    Api.saveArticle(this.content_edit_item).then(rres=>{
                        console.log(rres)
                       }).catch(e=>{})
                }
            }
        })
    }

    @observable page = 1
    @action setPage(num) {
        this.page = num
        this.getContentDataList()
    }
    count = 20
    total = 0
}
const distStore = new DistributeStore()
export default distStore
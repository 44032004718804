import React, { Fragment, Component } from 'react';
import Table from '../../../component/Table'
import Modal from '../../../component/Modal'
import {observer, inject} from 'mobx-react';
import Page from '../../../component/Page'
@inject("store")
@observer
class Content extends React.Component{

    componentDidMount(){
        const {distStore} = this.props.store 
        distStore.getContentDataList()
    }

    render() {
        const {distStore: {
            shareModalShow,
            content_edit_item,
            totle,
            count,
            page
        }} = this.props.store
        const {distStore} = this.props.store 
        let page_num = 1
        if(totle > 0 && totle % count > 0) {
            page_num = 1
        }
        if (totle > count) {
            page_num +=(Math.floor(totle/count))
        }
        return(
            <div className="container-fluid h-100 a-fadein">
                
                <div className="container pt-2 pb-2" style={{height: '98%', backgroundColor: "#ffffff"}}>
                    
                    {
                        content_edit_item ? 
                        <Fragment>
                            <div className="w-100 p-2 d-flex flex-row align-items-center justify-content-between">
                                <button onClick={()=>distStore.setEdit(null)} className="btn btn-primary">返回</button>
                                {
                                   content_edit_item.id != undefined ? 
                                   <div>
                                       {
                                          (!content_edit_item.media) ?
                                          <button onClick={()=>distStore.createImage(content_edit_item)} className="btn btn-primary ml-3">生成图片</button>:""
                                       }
                                       {
                                          (content_edit_item.video.startsWith('http')) ? "" :
                                          <button onClick={()=>distStore.createVedio(content_edit_item)} className="btn btn-primary ml-3">生成视频</button>
                                       } 
                                   </div> : ""
                                }
                            </div>
                            <div className="mb-3">
                                {
                                   content_edit_item.id != undefined?  
                                   <ContentEdit data={content_edit_item}></ContentEdit> 
                                   : 
                                   <ContentAdd></ContentAdd>
                                }
                            </div>
                        </Fragment>    
                        : 
                        <Fragment>
                            <div className="w-100 p-2 d-flex flex-row align-items-center justify-content-between">
                                <button onClick={()=>{
                                        distStore.setEdit({})
                                        distStore.setArticleContent("")
                                        distStore.setArticleUrl("")
                                        distStore.setArticleAbstract("")
                                    }} className="btn btn-primary">新增</button>
                            </div>
                            {this.renderData()}
                            {
                                page_num > 0 ? <Page pageCallbackFn={pageNum=>{distStore.setPage(pageNum)}} totalPage={page_num} currentPage={page}></Page>:""
                            }
                        </Fragment>  
                    }

                    {
                        shareModalShow ? 
                        <Modal>
                            <ShareModal></ShareModal>
                        </Modal> : ""
                    }

                    
                </div> 
            </div>
        )
    }

    

    renderData(){
        const {distStore: {
            content_data_list,
            page_num,
            page
        }} = this.props.store
        const { distStore } = this.props.store
        return(
            <Table
                dataList={content_data_list}
                pageNum={page_num}
                page={page}
                renderTableTitle={()=>{
                    return (
                            <tr className="row w-100 mx-0">
                                <th className="col border-top-0 border-right-0 border-bottom-0 text-center">摘要</th>
                                <th className="col border-top-0 border-right-0 border-bottom-0 text-center">URL</th>
                                <th className="col border-top-0 border-right-0 border-bottom-0 text-center">图片</th>
                                <th className="col border-top-0 border-right-0 border-bottom-0 text-center">音频</th>
                                <th className="col border-top-0 border-right-0 border-bottom-0 text-center">视频</th>
                                <th className="col border-top-0 border-right-0 border-bottom-0 text-center">操作</th>
                            </tr> 
                        )
                    }}
                    >
                      {
                          content_data_list.map((item, index) => {
                              return(
                                <tr key={`message-${index}`} className="row w-100 mx-0">
                                    <td className="col d-inline-block text-truncate border-right-0 border-left-0 border-bottom-0">{item.content}</td>
                                    <td className="col d-inline-block text-truncate border-bottom-0 border-right-0">{item.url}</td>
                                    <td className="col d-inline-block text-truncate border-bottom-0 border-right-0 ">{(item.media && item.media.length > 0 && item.media.startsWith('http')) ? item.media : "未生成"}</td>
                                    <td className="col d-inline-block text-truncate border-bottom-0 border-right-0">{(item.voice && item.voice.length > 0 && item.voice.startsWith('http')) ? item.voice : "未生成"}</td>
                                    <td className="col d-inline-block text-truncate border-bottom-0 border-right-0">{(item.video && item.video.length > 0 && item.video.startsWith('http')) ? item.video : "未生成"}</td>
                                    <td className="col border-bottom-0 border-right-0 text-center"><div onClick={()=>{
                                        distStore.setEdit(item)
                                        if(item.video.length > 0 && !item.video.startsWith('http')) {
                                            distStore.selectVedioStatus(item.video)
                                        }
                                    }} className="text-hover">发布</div></td>
                                </tr>
                              )
                          })
                      }  
            </Table>
        )
    }
}

@inject("store")
@observer
class ContentEdit extends React.Component{
    state = {
        tab_index: 0
    }
    render() {
        const { distStore } = this.props.store
        const data = this.props.data

        return(
            <div className="w-100">
                <div className="w-100 p-2">
                    <div className="mb-3 p-2" style={{backgroundColor: 'rgb(239,239,239)'}}>
                        您需要先到授权管理授权后才能分享。
                    </div>

                    <ul className="nav nav-pills border border-top-0 border-left-0 border-right-0 pb-2">
                        <li role="presentation" className="px-2"><div onClick={()=>this.setState({tab_index:0})} style={this.state.tab_index == 0 ? {color: '#007bff'} : {}}>文字</div></li>
                        <li role="presentation" className="px-2"><div onClick={()=>this.setState({tab_index:1})} style={this.state.tab_index == 1 ? {color: '#007bff'} : {}}>图片</div></li>
                        <li role="presentation" className="px-2"><div onClick={()=>this.setState({tab_index:2})} style={this.state.tab_index == 2 ? {color: '#007bff'} : {}}>视频</div></li>
                    </ul>

                    {
                        this.state.tab_index == 0 ? 
                        <div className="card mt-3">
                            <div className="card-body">
                                <p className="card-text">{data.content}{data.url}</p>
                                <a onClick={()=>distStore.setShareModealShow(true, 'text')} href="#" className="btn btn-primary">分享</a>
                            </div>
                        </div>:""
                    }


                    {
                        this.state.tab_index == 1 ? 
                        <div className="card mt-3 p-1" >
                            <img className="card-img-top" style={{width: '18rem'}} onError={()=>distStore.createImage(data)} alt="" src={data.media}></img>
                            <div className="card-body">
                                <a onClick={()=>distStore.setShareModealShow(true, 'image')} href="#" className="btn btn-primary">分享</a>
                            </div>
                        </div>:""
                    }


                    {
                        this.state.tab_index == 2 ? 
                        <div className="card mt-3 p-1" >
                            <video controls="controls" src={data.video} style={{width: '250px'}}></video>
                            <div className="card-body">
                                <a onClick={()=>distStore.setShareModealShow(true, 'video')} href="#" className="btn btn-primary">分享</a>
                            </div>
                        </div>:""
                    }

                    
                </div>
            </div>
        )
    }
}

@inject("store")
@observer
class ContentAdd extends React.Component{
    render() {
        const { distStore } = this.props.store
        const {distStore: {
            article_content,
            article_url,
            article_abstract
        }} = this.props.store
        return(
            <div className="w-100">
                <div className="w-100 p-2">
                    
                    <div className="mb-3 p-2" style={{backgroundColor: 'rgb(239,239,239)'}}>
                        当文章内容大于500字，包含标点符号。需要提取摘要发表
                    </div>
                    
                    <div className=" mb-3 row d-flex flex-row align-items-center">
                        <span className="col-2 text-center">文章内容</span>
                        <textarea defaultValue={article_content} onChange={e=>distStore.setArticleContent(e.target.value)} className="col-9 border p-2" rows={6}></textarea>
                    </div>

                    {
                        article_abstract.length > 0 ?
                        <div className=" mb-3 row d-flex flex-row align-items-center">
                            <span className="col-2 text-center">摘要内容</span>
                            <textarea defaultValue={article_abstract} onChange={e=>distStore.setArticleAbstract(e.target.value)} className="col-9 border p-2" rows={6}></textarea>
                        </div>:""
                    }
                    
                    <div className="mb-4 row d-flex flex-row align-items-center">
                        <span className="col-2 text-center">文章URL</span>
                        <input value={article_url} onChange={e=>{distStore.setArticleUrl(e.target.value)}} className="col-9 border p-2" ></input>
                    </div>
                    
                    <div className="mb-3 row d-flex flex-row align-items-center flex-row-reverse">
                        <button onClick={()=>{distStore.saveArticle()}} className="btn btn-primary ml-3 mr-4">保存</button>
                        <button onClick={()=>{distStore.txt2abstract(article_content)}} className="btn btn-success">提取摘要</button>
                    </div>
                </div>
            </div>
        )
    }
}

@inject("store")
@observer
class ShareModal extends Component{

    render(){
        const { distStore } = this.props.store
        const {distStore: {
            share_platform_account
        }} = this.props.store
        return(
            <div className="modal-content w-50">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalCenterTitle">分享</h5>
                    <button onClick={()=>distStore.setShareModealShow(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body py-2">
                    <div className="d-flex flex-row flex-wrap">
                        {
                            share_platform_account.map((account, index) => {
                                return(
                                    <div onClick={()=> distStore.selectShareAccount(account)} key={`account-${index}`} className="cursor card p-2 align-items-center justify-content-center" style={account.select ? {width: '25%', borderColor: '#007bff'} : {width: '25%'}}>
                                        <img className="card-img-top" alt="" src={account.avater} style={{width: 56, height: 56}}></img>
                                        <div className="card-body text-center">
                                            {account.name}
                                        </div>
                                    </div> 
                                )
                            })
                        }
                    </div>           
                    {/* <div className="d-flex flex-row align-items-center justify-content-between">
                                        
                        <button onClick={()=>{
                            if(share_platform_account.length > 0) {

                            }else {
                                alert('请绑定分享账号')
                            }
                        }} className="btn btn-primary" style={{width: '48%'}}>
                            直接分享
                        </button>

                        <button disabled className="btn btn-secondary" style={{width: '48%'}}>
                            富媒体分享                    
                        </button>
                    </div> */}

                </div>
                <div className="modal-footer">
                    {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button> */}
                    <button onClick={()=>distStore.sendShare()} type="button" className="btn btn-primary">发送</button>
                </div>
            </div>
                        
        )
    }
}



export default Content
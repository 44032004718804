import React from 'react'

class BindPage extends React.Component{

    render(){
        return(
            <div className="p-4 container-fluid d-flex align-items-center justify-content-center">
                <div>
                    <h3>绑定成功</h3>
                </div>
            </div>
        )
    }

}

export default BindPage
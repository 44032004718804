import {observable, action} from 'mobx';
import Api from '../utils/Api'


const message_test = [
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
    {
        id: "",
        title: "空间大/还便宜 五菱宏光PLUS预售5万起",
        content: "网易汽车5月15日报道 日前从官方获悉，宏光PLUS 1.5L车型将于5月底正式上市，该车将是宏光PLUS车型中的入门车型，预售价5-6万元，进一步降低了购车门槛，实惠的价格，还能享受更大的空间，相信会满足更多用户的需求，此次新车将推出三款不同配置车型，其中高配车型还将增加驾驶员气囊、倒车影像、皮座椅和中控屏等。",
        date_time: '2020-05-15 10:56:21',
        key: "网易汽车",
        status: 1
    },
]


class NewsFlash{
    @observable loading = false
    /**
     *  全部媒体集合
     */
    @observable media_all = [] 
    @action getMediaAll() {
        this.loading = true
        let array = []
        array.push(Api.mediaList())
        array.push(Api.messageStatusList())

        Promise.all(array).catch(e=>{}).then(array=>{
            console.log(array)
            let media_all_res = array[0]
            let news_status_res = array[1]
            if(media_all_res && 200 == media_all_res.code) {
                this.media_all = media_all_res.data
                
                let media_ids = ''
                this.media_all.map(item=>media_ids += `,${item.id}`)
                this.send_media_ids = media_ids.substring(1, media_ids.length)
            }
            if(news_status_res && 200 == news_status_res.code) {
                this.news_status = news_status_res.data
                
                if(this.news_status.length > 0) {
                    this.message_status = this.news_status[0].key
                }
                
            }
            this.loading = false
        })
    }
    @action resetData(){
        this.message_id = ""
        this.message_title = ""
        this.message_content = ""
        this.message_url = ""
    }

    @observable news_status = []


    @observable media_message_list = []
    @observable key_word = ""
    @observable media_ids = ""
    @observable message_status = ""
    @observable page = 1
    @action setPage(num) {
        this.search(num)
    }
    count = 15
    @observable totle = 0

    @action search(page = 1){
        this.media_message_list = []
        this.loading = true
        Api.getMessage(page, this.count).then(data=>{
            if(data && 200 == data.code) {
                this.totle = data.data.total
                this.media_message_list = data.data.list
            }
            this.page = page
            this.loading = false
        })
    }


    @observable message_id = ""
    @action setMessageId(id) {
        this.message_id = id
    }
    @observable message_title = ""
    @action setTitle(title) {
        this.message_title = title
    }
    @observable abstract = ""
    @action setAbstract(abstract) {
        this.abstract = abstract
    }
    @observable message_content = ""
    @action setContent(content) {
        this.message_content = content
    }
    @observable send_media_ids = ""
    @action setMediaIds(ids) {
        this.send_media_ids = ids
    }
    @observable message_status = ""
    @action setStatus(status) {
        this.message_status = status
    }
    @observable message_type1 = ""
    @action setMessageType1(type) {
        this.message_type1 = type
    }
    @observable message_type2 = ""
    @action setMessageType2(type) {
        this.message_type2 = type
    }
    
    @observable error = ""

    @observable message_url = ""
    @action setUrl(url) {
        this.message_url = url
    }
    /**
     *  发送消息
     *  url       文章原地址
     *  id        如果不为空就是修改
     *  title     可能为空
     *  abstract  摘要 可能为空
     *  message   内容不为空 长度在500以内
     *  status    状态  （待发布/未上线0） （发布/已上线1）  下线/下架-1）
     *  media_ids 发送到媒体的id   id,id,id  最少有一个
     *  types     数据分类    金融,央行  最少有个一个
     */
    @action sendMessage() {
        console.log(`sendMessage:${this.error}`)
        this.error = ""
        if(this.message_content.length <= 0) {
            this.error = "快讯必须有内容"
            return
        }

        this.loading = true

        let param = {
            id: this.message_id,
            title: this.message_title,
            abstract: this.abstract,
            message: this.message_content,
            status: this.message_status,
            media_ids: this.send_media_ids,
            url: this.message_url,
            types: ""
        }

        if (this.message_id.length <= 0) {
            delete param.id
        }

        Api.sendMessage(param).then(data=>{
            console.log(data)
            if(data && data.code == 200) {
                console.log(`data`)
                
                
                this.message_title = ""
                this.abstract = ""
                this.message_content = ""
                this.message_url = ""
                this.message_id = ""

                console.log(`data ${this.message_content}`)
                 
            }
            this.loading = false
        }).catch(e=>{
            this.loading = false
        })


    }
}
const nf = new NewsFlash();
export default nf